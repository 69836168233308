import "../../styles/application-sections.css"

import React, {
    forwardRef,
    Fragment,
    useEffect,
    useImperativeHandle,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react"

import axios from "axios"
import { navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import {
    Controller,
    useForm,
} from "react-hook-form"
import ReactInputMask from "react-input-mask"

import {
    PlusCircleIcon,
    PlusIcon,
    XIcon,
} from "@heroicons/react/solid"

import ApplicationAccordion from "../../components/shared/application-accordion"
import { API_BASE_URL } from "../../constants/config"
import {
    defaultPastDogs,
    defaultPets,
    defaultReference,
    dwellTypes,
    emailFormat,
    genders,
    indoorOutdoor,
    insideOutside5050,
    ownRent,
    phoneFormat,
    phoneFormatField,
    phoneTypes,
    yesNo,
    zipFormat,
    zipFormatField,
} from "../../constants/defaults"
import { parseError } from "../../constants/utility"
import {
    alertInitialState,
    alertReducer,
} from "../../reducers/alert-reducer"
import Alert from "../shared/Alert"
import InlineLoader from "../shared/inline-loader"
import RegisterTab from "../shared/register-tabs"

const MultipleFields = forwardRef(({ title, data, fields, onUpdateField, onAdd, onRemove, classCols,addLabel }, ref) => {
    const { formState: { errors }, trigger, control } = useForm();
    useImperativeHandle(ref, () => ({

        validate: async () => {
            return await trigger();
        }

    }));

    return <Fragment>
        <ApplicationAccordion onRemove={(index) => { onRemove(index) }}>

            {data && data.map((item, fkey) => {
                return <div key={fkey} title={`${title} #${fkey + 1}`}>
                    <div className={`form-fields ${classCols}`}>
                        {fields && fields.map((field, key) => {
                            var field_key = field.key;
                            var s_field = field;
                            return <div key={"l" + key} className={`field-group ${field.className}`}>
                                <label htmlFor={field.key}>{field.label !== "" ? field.label : <span>&nbsp;</span>} {field.required === true && field.label !== "" && <span className="req">*</span>}</label>
                                {/* {['text', 'number'].indexOf(field.type) > -1 && <input onChange={(e) => {

                                    onUpdateField(fkey, field.key, e.target.value);

                                }} {...register(`${fkey}_${field.key}`, { required: field.required })} type={field.type} />} */}

                                {field.type === 'text' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}

                                    render={({ field: { onChange } }) => (
                                        <input
                                            {...field}
                                            onChange={(e) => {
                                                
                                                onUpdateField(fkey, field_key, e.target.value);
                                                onChange(e);
                                            }}
                                            type="text"
                                            value={item[field_key]}
                                        />
                                    )}
                                />}
                                {field.type === 'textarea' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}

                                    render={({ field: { onChange } }) => (
                                        <textarea
                                            {...field}
                                            onChange={(e) => {
                                                onUpdateField(fkey, field_key, e.target.value);
                                                onChange(e);
                                            }}
                                            resize="none"
                                            value={item[field_key]}
                                        />
                                    )}
                                />}
                                {field.type === 'number' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}

                                    render={({ field: { onChange } }) => (
                                        <input
                                            {...field}
                                            onKeyDown={(e)=>{

                                                //fix firefox
                                                if(e.keyCode===190 && String(e.target.value).indexOf('.')>-1){
                                                    e.preventDefault();
                                                }

                                                if((e.keyCode>=48 && e.keyCode<=57) || [190,8].indexOf(e.keyCode)>-1 ){
                                                    console.log(e.keyCode)
                                                }
                                                else{
                                                    e.preventDefault();
                                                }
                                               
                                            }}
                                            onChange={(e) => {
                                               
                                                onUpdateField(fkey, field_key, e.target.value);
                                                onChange(e);
                                            }}
                                            type="text"
                                            value={item[field_key]}
                                        />
                                    )}
                                />}


                                {field.type === 'select' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}
                                    defaultValue={item[field_key]}
                                    render={({ field: { onChange } }) => (
                                        // <input
                                        //     {...field}
                                        //     onChange={(e) => {
                                        //         onUpdateField(fkey, field_key, e.target.value);
                                        //         onChange(e);
                                        //     }}
                                        //     type="number"
                                        //     value={item[field_key]}
                                        // />
                                        <select {...field} value={item[field_key]} onChange={(e) => {
                                            onUpdateField(fkey, field_key, e.target.value);
                                            onChange(e);
                                        }}>
                                            {s_field.placeholder === true && <option value="">Select</option>}
                                            {s_field.data_source && s_field.data_source.map((s, k) => {
                                                return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                            })}
                                        </select>
                                    )}
                                />}



                                {field.type === 'phone' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required, pattern: field.pattern }}
                                    render={({ field: { onChange } }) => (

                                        <ReactInputMask   {...field} onChange={(e) => {
                                            onUpdateField(fkey, field_key, e.target.value);
                                            onChange(e);
                                        }}

                                            type={"text"} value={item[field_key]} mask={phoneFormatField} />
                                    )}
                                />}
                                {field.type === 'radio' && field.key.indexOf('gender') > -1 && <div className="flex md:flex-row">
                                    {field.data_source && field.data_source.map((v, key) => {
                                        return v !== 'Either' && <label className="mr-3 force-font-normal" key={"v" + key}>

                                            <Controller
                                                control={control}
                                                name={`${fkey}_${field.key}`}
                                                rules={{ required: field.required }}
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        onChange={(e) => {
                                                            onUpdateField(fkey, field_key, e.target.value);
                                                            onChange(e);
                                                        }}
                                                        checked={v === item[field_key]} value={v}
                                                    />
                                                )}
                                            /> {v}</label>
                                    })}
                                </div>}

                                {field.type === 'radio' && field.key.indexOf('gender') === -1 && field.key !== 'pet_current_inside_outside' && <div className="flex md:flex-row">
                                    {field.data_source && field.data_source.map((v, key) => {
                                        return v !== 'Either' && <label className="mr-3 force-font-normal" key={"v" + key}>


                                            <Controller
                                                control={control}
                                                name={`${fkey}_${field.key}`}
                                                rules={{ required: field.required }}
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        onChange={(e) => {
                                                            onUpdateField(fkey, field_key, e.target.value);
                                                            onChange(e);
                                                        }}
                                                        checked={v.value === parseInt(item[field_key])} value={v.value}
                                                    />
                                                )}
                                            /> {v.label}</label>
                                    })}
                                </div>}
                                {field.type === 'radio' && field.key === 'pet_current_inside_outside' && <div className="flex md:flex-row">
                                    {field.data_source && field.data_source.map((v, key) => {
                                        return v !== 'Either' && <label className="mr-3 force-font-normal" key={"v" + key}>


                                            <Controller
                                                control={control}
                                                name={`${fkey}_${field.key}`}
                                                rules={{ required: field.required }}
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        onChange={(e) => {
                                                            onUpdateField(fkey, field_key, e.target.value);
                                                            onChange(e);
                                                        }}
                                                        checked={String(v.value) === item[field_key]} value={v.value}
                                                    />
                                                )}
                                            /> {v.label}</label>
                                    })}
                                </div>}

                                {errors[`${fkey}_${field.key}`] && errors[`${fkey}_${field.key}`].type === 'required' && <span className="text-red-600">This field is required</span>}
                                {errors[`${fkey}_${field.key}`] && errors[`${fkey}_${field.key}`].type === 'pattern' && field.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                {errors[`${fkey}_${field.key}`] && errors[`${fkey}_${field.key}`].type === 'pattern' && field.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}

                            </div>
                        })}
                    </div>
                </div>
            })}

        </ApplicationAccordion>
        <button onClick={onAdd} className="btn-accent btn-addmore-pets" type="button"><PlusIcon className="w-5 h-5" /> {addLabel}</button>
    </Fragment>

});
const Section1 = () => {

    return <div className="app-page-sec">
        <div className="content">
            <p>Foster homes are the most important component of DFWLRRC, we can only save as many dogs as we have foster homes. A foster parent provides a safe and loving environment for dogs until they are adopted. All medical care for the foster dog is taken care of by DFWLRRC.</p>
        </div>
    </div>
}

const Section2 = () => {
    const [states, setStates] = useState([]);
    const [pets, setPets] = useState([defaultPets]);
    const [pastDogs, setPastDogs] = useState([defaultPastDogs]);

    const [agesPeople, setAgesPeople] = useState([]);
    const [references, setReferences] = useState([defaultReference]);

    const [busy, setBusy] = useState(false);
    const [alertState, dispatchAlert] = useReducer(alertReducer, alertInitialState);
    const { register, formState: { errors }, watch, trigger, getValues, setValue } = useForm();
    const referenceRef = useRef();
    const petsRef = useRef();
    const pastDogsRef = useRef();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const personal_info_fields = useMemo(() => ([
        { label: "First Name", key: "first_name", type: "text", required: true, className: 'half' },
        { label: "Last Name", key: "last_name", type: "text", required: true, className: 'half' },
        { label: "Spouse's First Name", key: "spouse_first_name", type: "text", required: false, className: 'half' },
        { label: "Spouse's Last Name", key: "spouse_last_name", type: "text", required: false, className: 'half' },
        { label: "Street Address", key: "street_address", type: "text", required: true, className: 'w-full' },
        { label: "City", key: "city", type: "text", required: true, className: 'one-third' },
        { label: "State", key: "state", type: "select", required: true, className: 'one-third', data_source: states, placeholder: true },
        { label: "Zipcode", key: "zipcode", type: "zip", pattern: zipFormat, required: true, className: 'one-third' },
        { label: "Primary Phone", key: "primary_phone_type", type: "select", required: true, className: 'half', data_source: phoneTypes },
        { label: "", pattern: phoneFormat, key: "primary_phone", type: "phone", required: true, className: 'half' },
        { label: "Alternative Phone", key: "alternative_phone_type", type: "select", required: false, className: 'half', data_source: phoneTypes },
        { label: "", pattern: phoneFormat, key: "alternative_phone", type: "phone", required: false, className: 'half' },

        { label: "Email Address", pattern: emailFormat, key: "email", type: "email", required: true, className: 'half', desc: "We communicate primarily through email" },
        { label: "Employer", key: "employer", type: "text", required: false, className: 'half' },
    ]), [states]);

    const pets_fields = useMemo(() => ([
        { label: "Name", key: "pet_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Breed", key: "pet_breed", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Age (years)", key: "pet_age", type: "number", min: 0, required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Gender", key: "pet_gender", type: "radio", required: true, data_source: genders, className: 'col-span-3' },
        { label: "Spayed or Neutered?", key: "pet_spayed_neutered", type: "radio", required: true, data_source: yesNo, className: 'col-span-3' },
        { label: "Current on vaccinations?", key: "pet_current_vaccinations", type: "radio", required: true, data_source: yesNo, className: 'col-span-3' },
        { label: "Current on Heartworm Prevention?", key: "pet_current_heartworm", type: "radio", required: true, data_source: yesNo, className: 'col-span-3' },
        { label: "Is the dog kept inside or outside?", key: "pet_current_inside_outside", type: "radio", required: true, data_source: insideOutside5050, className: 'col-span-3' },

    ]), []);

    const vet_info_fields = useMemo(() => ([
        { label: "Veterinary Clinic", key: "vet_clinic_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Veterinarian", key: "vet_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Office Phone", key: "vet_phone", type: "phone", pattern: phoneFormat, required: true, className: 'col-span-3 md:col-span-1' },
        { label: "What other names or phone numbers may your dog(s) be listed under at this vet? Please list any additional vets or relevant information.", key: "vet_listed_dog_names", type: "textarea", required: false, className: 'col-span-3' },
    ]), []);

    const reference_fields = useMemo(() => ([
        { label: "Name", key: "ref_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Years Known", key: "ref_years_known", type: "number", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Primary Phone", key: "ref_primary_phone_type", data_source: phoneTypes, type: "select", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "", key: "ref_primary_phone", pattern: phoneFormat, type: "phone", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Alternative Phone", key: "ref_alternative_phone_type", data_source: phoneTypes, type: "select", required: false, className: 'col-span-3 md:col-span-1' },
        { label: "", key: "ref_alternative_phone", pattern: phoneFormat, type: "phone", required: false, className: 'col-span-3 md:col-span-1' },

    ]), []);

    const environment_fields = useMemo(() => ([
        { label: "Have you ever owned a Labrador Retriever?", key: "own_labrador", type: "radio", required: true, data_source: yesNo, className: 'col-span-2' },
        { label: "Are you willing to take time with your foster for training (crate, house, obedience, etc) while under your care?", key: "take_time_foster", type: "radio", required: true, data_source: yesNo, className: 'col-span-2' },
        { label: "Do you own a crate? (DFW Lab Rescue can provide a crate if necessary)", key: "own_create", type: "radio", required: true, data_source: yesNo, className: 'col-span-2' },
        { label: "Will the DFW Lab Foster mainly stay indoors or out?", key: "stay_indoor_outdoor", type: "select", data_source: indoorOutdoor, required: true, className: 'col-span-2 md:col-span-1' },
        { label: "Where will you keep the foster during the day?", key: "foster_during_day", type: "text", required: true, className: 'col-span-2 md:col-span-1' },
        { label: "Where will you keep the foster at night?", key: "foster_during_night", type: "text", required: true, className: 'col-span-2 md:col-span-1' },
        { label: "What type of foster dog will work best with your family & other pets (good with kids, good with cats, etc.)", key: "type_of_foster", type: "text", required: true, className: 'col-span-2 ' },
        { label: "What age would you prefer to foster?", key: "age_prefer_to_foster", type: "text", required: true, className: 'col-span-2 md:col-span-1' },
        { label: "What gender would you prefer to foster?", key: "gender_prefer_to_foster", type: "select", data_source: genders, required: true, className: 'col-span-2 md:col-span-1' },
    ]), []);

    const questions_fields = useMemo(() => ([
        { label: "How did you hear about DFW Lab Rescue?", key: "how_did_you_hear", type: "text", required: true, className: 'col-span-2' },
        { label: "Please explain why you want to foster a Labrador Retriever", key: "explain_why_foster", type: "textarea", required: true, className: 'col-span-2' },
        { label: "Additional Comments", key: "additional_comments", type: "textarea", required: true, className: 'col-span-2' },
    ]), []);

    const past_dogs_fields = useMemo(() => ([
        { label: "Name", key: "past_dog_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Breed", key: "past_dog_breed", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Age Reached (years)", key: "past_dog_age_reached", type: "number", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Gender", key: "past_dog_gender", type: "radio", required: true, data_source: genders, className: 'col-span-3' },
        { label: "What happened?", key: "past_dog_what_happen", type: "textarea", required: true, className: 'col-span-3' },


    ]), []);

    useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/static/states").then(({ data }) => {
                setStates(data.data);
            })

        }
        return () => {
            m = false;
        }
    }, [])

    const ages_number = watch("ages_number");
    const understand = watch("understand");
    const dwelling_type = watch("dwelling_type");
    const own_rent = watch("own_rent");
    const special_regulations = watch("special_regulations");
    const fenced_yard = watch("fenced_yard");
    const have_pets = watch("have_pets");
    const have_vet = watch("have_vet");
    const rehomed_dog = watch('rehomed_dog');
    const have_past_dogs = watch("have_past_dogs");
    useEffect(() => {
        var m = true;
        if (m) {

            if (have_pets === '0') {

                setPets([defaultPets]);
            }
        }
        return () => {
            m = false;
        }
    }, [have_pets])


    const onSubmit = async (data) => {
        if (busy) {

            return;
        }
        if (!executeRecaptcha) {

            return;
        }
        if (parseInt(have_pets) === 0) {
            setPets([]);
        }
        if (parseInt(have_past_dogs) === 0) {
            setPastDogs([]);
        }
        setBusy(true);
        dispatchAlert({ type: 'reset' });
        return new Promise((resolve) => {
            setTimeout(async () => {
                const token = await executeRecaptcha('foster')

                data = { ...data, pets: pets, references: references, ages_people: agesPeople, past_dogs: pastDogs, token: token }
                axios.post(API_BASE_URL + "/website/application/foster", data).then(({ data }) => {
                    setBusy(false);
                    navigate("/thank-you-foster-application");
                }).catch(({ response }) => {
                    setBusy(false);
                    if (response) {
                        var { data } = response;
                        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop, behavior: 'smooth' })
                        dispatchAlert({ type: 'error', text: parseError(data) })

                    }
                })
                resolve()
            }, 3000);
        });
    }



    const validateField = async (index) => {


        var fields = [];
        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop - 20, behavior: 'smooth' })
        personal_info_fields.forEach((item, key) => {
            fields.push(item.key);
        })

        var error = 0;
        var success = false;

        if (index >= 0) {
            fields.push("understand");
            success = await trigger(fields);
            error += success ? 0 : 1;

        }

        if (index >= 1) {
            fields = ['dwelling_type', 'own_rent', 'special_regulations', 'fenced_yard', 'swimming_pool'];
            success = await trigger(fields);
            error += success ? 0 : 1;
        }

        if (index >= 2) {
            fields = ['have_pets', 'other_pets', 'have_past_dogs', 'have_vet', 'contact_vet', 'rehomed_dog', 'rehomed_dog_why', 'last_time_saw_vet', 'brand_of_dog_food', 'type_of_heartworm'];
            success = await trigger(fields);
            error += success ? 0 : 1;


            if (parseInt(getValues("have_pets")) === 1) {
                success = await petsRef.current.validate();
                error += success ? 0 : 1;
            }
            if (have_past_dogs === '1') {
                success = await pastDogsRef.current.validate();
                error += success ? 0 : 1;
            }

            fields = [];
            vet_info_fields.forEach((item, key) => {
                fields.push(item.key);
            })

            success = await trigger(fields);
            error += success ? 0 : 1;
        }

        if (index >= 3) {
            fields = [];
            environment_fields.forEach((item, key) => {
                fields.push(item.key);
            })
            success = await trigger(fields);
            error += success ? 0 : 1;

        }

        if (index >= 4) {
            success = await referenceRef.current.validate();
            error += success ? 0 : 1;
        }




        return (error === 0);
    }

    useEffect(() => {
        //debug
        var m = true;
        if (m) {

            setTimeout(() => {
                setValue("state", "TX");
            }, 1000)

            // Debug
            
            // setValue("first_name", "Bill");
            // setValue("last_name", "Gates");
            // setValue("spouse_first_name", "Jane");
            // setValue("spouse_last_name", "Doe");
            // setValue("street_address", "Wall Street");
            // setValue("city", "Phoenix");

            // setValue("rehomed_dog", '0');
            // setValue("last_time_saw_vet","Last month");

            // setValue("type_of_heartworm", 'heart worm test');
            // setValue("brand_of_dog_food", 'pedigree');

            // setValue("state", "AZ");
            // setValue("zipcode", "85006");
            // setValue("primary_phone_type", "Mobile");
            // setValue("primary_phone", "(502) 332-3232");
            // setValue("alternative_phone_type", "Work");
            // setValue("alternative_phone", "(502) 333-4444");
            // setValue("email", "natmbndev3@gmail.com");
            // setValue("employer", "My Biz Niche");

            // setValue("dwelling_type", "House");
            // setValue("own_rent", "Own");
            // setValue("special_regulations", "0");
            // setValue("fenced_yard", "0");
            // setValue("swimming_pool", "0");
            // setValue("have_pets", "1");
            // setValue("have_vet", "1");
            // setValue("contact_vet", "1");



            // setPets([{
            //     pet_name: "Stark",
            //     pet_breed: "Pom",
            //     pet_age: "8",
            //     pet_gender: "Male",
            //     pet_spayed_neutered: "1",
            //     pet_current_vaccinations: "1",
            //     pet_current_heartworm: "1",
            //     pet_current_inside_outside: "Inside"
            // }])

            // setValue("vet_clinic_name", 'Petropolis');
            // setValue("vet_name", 'Bill Gates');
            // setValue("vet_phone", '(502) 782-4411');
            // setValue("vet_listed_dog_names", 'stark, pepper, sherlock, suzie, penny, tokyo, chrysler');

            // setReferences([
            //     {
            //         ref_name: "Jeff Bezos",
            //         ref_years_known: "5",
            //         ref_primary_phone_type: "Mobile",
            //         ref_primary_phone: "(502)-332-1123",
            //         ref_alternative_phone_type: "Work",
            //         ref_alternative_phone: "(502)-332-1223",
            //     }
            // ])

            // setValue("own_labrador", "0");
            // setValue("take_time_foster", "1");
            // setValue("own_create", "1");
            // setValue("stay_indoor_outdoor", "Indoor");
            // setValue("foster_during_day", "during day");
            // setValue("foster_during_night", "during night");
            // setValue("type_of_foster", "good with dogs");
            // setValue("age_prefer_to_foster", "1");
            // setValue("gender_prefer_to_foster", "Either");
            // setValue("how_did_you_hear", "Google");
            // setValue("explain_why_foster", "test why");
            // setValue("additional_comments", "Test comments");
            // setValue("understand", true);

        }
        return () => {
            m = false;
        }
    }, [setValue])


    return <div className="app-page-sec">
        <div className="content">
            <Alert data={alertState} />
            <form>
                <div className="flex flex-col mb-6 terms-container">
                    <label className={"terms-checkbox  " + (understand ? "active" : "")}><input type="checkbox" {...register("understand", { required: true })} />I understand that I am filling out a foster application and not an adoption application. <span className="req">*</span></label>
                    {errors.understand && <span className="text-red-600 block text-sm">This field is required</span>}
                </div>
                <RegisterTab
                    busy={busy}
                    onSelectTab={async (index) => {
                        return await validateField(index > 0 ? (index - 1) : index);
                    }}
                    onPrev={() => {
                        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop, behavior: 'smooth' })
                    }}
                    onNext={async (index) => {
                        console.log(references);
                        return await validateField(index);
                    }} onFinish={async (index) => {
                        var validate = await trigger();
                        if (validate) {
                            onSubmit(getValues())
                        }
                    }}
                >
                    <div title="Contact Information">
                        <h3>Contact Information</h3>
                        <div className="form-fields form-flex">
                            {personal_info_fields && personal_info_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>
                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.type === 'zip' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={zipFormatField} />}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'zip' && <span className="text-red-600">Invalid Zip Format</span>}
                                </div>
                            })}
                        </div>
                    </div>
                    <div title="Housing">
                        <h3>Housing</h3>
                        <div className="form-fields">
                            <div className="field-group col-span-2 md:col-span-1">
                                <label htmlFor="how_many_children">How many children are living in your home?</label>
                                <input type="number" min="0" max={99} {...register('how_many_children', { valueAsNumber: true })} />
                            </div>
                            <div className="field-group col-span-2 md:col-span-1">
                                <label htmlFor="what_are_the_ages">What are the ages of the people living in your home?</label>
                                <div className="flex flex-row flex-wrap items-center">
                                    {agesPeople && agesPeople.map((item, k) => {
                                        return <span key={k} className="p-2 flex items-center text-sm mx-1 rounded-md bg-orange-light text-white">{item}
                                            <button type="button" onClick={() => {
                                                var ages = agesPeople.filter((f, i) => {
                                                    return k !== i;
                                                })
                                                setAgesPeople([...ages])
                                            }}><XIcon className="w-4 h-4 text-white ml-1" /></button>
                                        </span>
                                    })}
                                    <div className="flex flex-row">
                                        <input type="number" {...register("ages_number")} max="99" maxLength="2" className="txt_ages_number" min="1" />
                                        <button type="button" onClick={() => {
                                            if (ages_number === '') {
                                                return;
                                            }
                                            setAgesPeople((state) => ([...state, ages_number]))
                                            setValue('ages_number', '');
                                        }} ><PlusCircleIcon className="w-5 h-5 text-yellow-500" /></button>
                                    </div>
                                </div>

                            </div>
                            <div className="field-group col-span-2 md:col-span-1">
                                <label htmlFor="dwelling_type">Dwelling Type <span className="req">*</span></label>
                                <select {...register('dwelling_type', { required: true })}>
                                    <option value="">Select</option>
                                    {dwellTypes && dwellTypes.map((item, key) => {
                                        return <option key={key} value={item}>{item}</option>
                                    })}

                                </select>
                                {dwelling_type === 'Other' && <input className="mt-1" placeholder="provide other dwelling type" type="text" {...register('other_dwell_type', { required: true })} />}
                                {dwelling_type === 'Other' && errors.other_dwell_type && <span className="text-red-500">Please provide other dwelling type</span>}
                                {errors.dwelling_type && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="field-group col-span-2 md:col-span-1">
                                <label htmlFor="how_long_have_you_lived">How long have you lived at current address?</label>
                                <input type="text" {...register('how_long_have_you_lived')} />
                                <span className="desc">ex. 5 years, 3 months, 1 and half years</span>
                            </div>
                            <div className="field-group col-span-2">
                                <label htmlFor="own_rent">Do you own or rent? <span className="req">*</span></label>
                                <select {...register('own_rent', { required: true })}>
                                    <option value="">Select</option>
                                    {ownRent && ownRent.map((item, key) => {
                                        return <option key={key} value={item}>{item}</option>
                                    })}
                                </select>
                                {errors.own_rent && <span className="text-red-500">This field is required</span>}
                            </div>
                            {own_rent === 'Rent' && <div className="field-group col-span-2 md:col-span-1">
                                <label htmlFor="landlord_name">Landlord Name</label>
                                <input type="text" {...register('landlord_name')} />
                            </div>}
                            {own_rent === 'Rent' && <div className="field-group col-span-2 md:col-span-1">
                                <label htmlFor="landlord_phone_number">Landlord Phone Number</label>
                                <ReactInputMask type={"text"} {...register('landlord_phone_number', { pattern: phoneFormat })} mask={phoneFormatField} />
                                {errors.landlord_phone_number && errors.landlord_phone_number.type === 'pattern' && <span className="text-red-600">Invalid Phone Format</span>}
                            </div>}
                            <div className="field-group field-group col-span-2">
                                <label htmlFor="special_regulations">Are there any special regulations concerning pets? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((item, key) => {
                                        return <label className="mr-3 force-font-normal" key={key}><input {...register('special_regulations', { required: true })} type="radio" value={item.value} /> {item.label}</label>
                                    })}
                                </div>
                                {special_regulations === '1' && <input placeholder="If yes, please explain..." type="text" {...register('special_regulations_explain')} />}
                                {errors.special_regulations && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div className="field-group field-group col-span-2">
                                <label htmlFor="fenced_yard">Does your home have a fenced yard? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((item, key) => {
                                        return <label className="mr-3 force-font-normal" key={key}><input {...register('fenced_yard', { required: true })} type="radio" value={item.value} /> {item.label}</label>
                                    })}
                                </div>
                                {fenced_yard === '1' && <input placeholder="If yes, please describe your fence including height and type" type="text" {...register('fenced_yard_explain')} />}
                                {errors.fenced_yard && <span className="text-red-500">This field is required</span>}
                            </div>
                            <div className="field-group field-group col-span-2">
                                <label htmlFor="swimming_pool">Does your home have a swimming pool? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((item, key) => {
                                        return <label className="mr-3 force-font-normal" key={key}><input {...register('swimming_pool', { required: true })} type="radio" value={item.value} /> {item.label}</label>
                                    })}
                                </div>
                                {errors.swimming_pool && <span className="text-red-500">This field is required</span>}
                            </div>
                        </div>
                    </div>
                    <div title="Pet Ownership">
                        <h3>Pet Ownership</h3>
                        <div className="form-fields">
                            <h4 className="mb-5">Care</h4>
                        </div>
                        <div className="form-fields">
                            <div className="field-group col-span-2">
                                <label>Have you ever rehomed or surrendered a dog to a shelter, rescue or other individual? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("rehomed_dog", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['rehomed_dog'] && <span className="text-red-600">This field is required</span>}
                            </div>
                            {rehomed_dog === '1' && <div className="field-group col-span-2">
                                <label>If so, please tell us why? <span className="req">*</span></label>
                                <textarea resize="none" {...register("rehomed_dog_why", { required: true })} />
                                {errors['rehomed_dog_why'] && <span className="text-red-600">This field is required</span>}
                            </div>}
                            {<div className="field-group field-group col-span-2">
                                <label htmlFor="have_vet">Do you have a vet or have you had one for a prior dog? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((item, key) => {
                                        return <label className="mr-3 force-font-normal" key={key}><input {...register('have_vet', { required: true })} type="radio" value={item.value} /> {item.label}</label>
                                    })}
                                </div>
                                {errors.have_vet && <span className="text-red-500">This field is required</span>}
                            </div>}
                            {have_vet === '1' && <div className="field-group col-span-2">
                                <label>When was the last time you saw your vet? <span className="req">*</span></label>
                                <input type="text" {...register("last_time_saw_vet", { required: true })} />
                                {errors['last_time_saw_vet'] && <span className="text-red-600">This field is required</span>}
                            </div>}
                            {have_vet === '1' && <div className="field-group field-group col-span-2">
                                <label htmlFor="contact_vet">May we contact him/her? <span className="req">*</span></label>
                                <div className="flex md:flex-row">
                                    {yesNo && yesNo.map((item, key) => {
                                        return <label className="mr-3 force-font-normal" key={key}><input {...register('contact_vet', { required: true })} type="radio" value={item.value} /> {item.label}</label>
                                    })}

                                </div>
                                {errors.contact_vet && <span className="text-red-500">This field is required</span>}
                                <span className="desc">Vet may be contacted by the rescue regarding the general veterinary care of current/previous pets such as vaccinations, HW preventation, and spay/neuter.</span>

                            </div>}



                            {have_vet === '1' && <div className="form-fields mb-5 col-span-2">

                                {vet_info_fields && vet_info_fields.map((item, key) => {
                                    return <div key={"l" + key} className={`field-group ${item.className}`}>
                                        {item.type !== 'checkbox' && <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>}
                                        {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                            {item.placeholder === true && <option value="">Select</option>}
                                            {item.data_source && item.data_source.map((s, k) => {
                                                return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                            })}
                                        </select>}
                                        {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                        {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                        {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                        {item.type === 'textarea' && <textarea resize="none" {...register(item.key, { required: item.required })} />}
                                        {item.type === 'checkbox' && <label className={"terms-checkbox " + (watch(item.key) ? "active" : "")}><input type="checkbox" {...register(item.key, { required: item.required })} />{item.label}&nbsp;{item.link}</label>}
                                        {item.desc && <span className="desc">{item.desc}</span>}
                                        {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                        {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                        {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                    </div>
                                })}

                            </div>}
                        </div>
                        <div className="form-fields mb-4">
                            <div className="field-group col-span-2">
                                <label>What type of heartworm preventative do you give? <span className="req">*</span></label>
                                <input type="text"{...register("type_of_heartworm", { required: true })} />
                                {errors['type_of_heartworm'] && <span className="text-red-600">This field is required</span>}
                            </div>
                            <div className="field-group col-span-2">
                                <label>What brand of dog food do you feed? <span className="req">*</span></label>
                                <input type="text"{...register("brand_of_dog_food", { required: true })} />
                                {errors['brand_of_dog_food'] && <span className="text-red-600">This field is required</span>}
                            </div>
                        </div>
                        <div className="form-fields">
                            <h4>Current Dogs <span>Please list all dogs currently in residence</span></h4>
                            <div className="field-group field-group col-span-2">
                                <label htmlFor="have_pets">Do you have current dogs? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((item, key) => {
                                        return <label className="mr-3 force-font-normal" key={key}><input {...register('have_pets', { required: true })} type="radio" value={item.value} /> {item.label}</label>
                                    })}
                                </div>
                                {errors.have_pets && <span className="text-red-500">This field is required</span>}
                            </div>
                        </div>
                        {have_pets === '1' && <MultipleFields addLabel={'Add Another Current Dog'} ref={petsRef} classCols="col-2" title="Current Dog"
                            data={pets} fields={pets_fields} onUpdateField={(key, field, value) => {

                                var newPets = pets.map((pet, pk) => {
                                    if (key === pk) {
                                        var newPet = Object.assign({}, pet);
                                        newPet[field] = value;
                                        return newPet;
                                    }
                                    return pet;
                                })
                                setPets(newPets);
                            }}

                            onAdd={() => {
                                setPets((state) => ([...state, defaultPets]));
                            }}
                            onRemove={(index) => {
                                var newPets = pets.filter((pet, i) => {
                                    return i !== index;
                                })

                                setPets(newPets);

                            }}
                        />}

                        <div className="form-fields">
                            <h4 className="col-span-2 mb-0">Past Dogs <span>Please list all dogs you were the primary caretaker of, but no longer have, and describe what happened to each.</span></h4>
                            <div className="field-group col-span-2">
                                <label>Do you have past dogs? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("have_past_dogs", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['have_past_dogs'] && <span className="text-red-600">This field is required</span>}
                            </div>
                        </div>

                        {have_past_dogs === '1' && <MultipleFields addLabel="Add Another Past Dog" is_optional={true} classCols={"col-2"} ref={pastDogsRef} title="Past Dog"
                            data={pastDogs} fields={past_dogs_fields} onUpdateField={(key, field, value) => {
                                var data = pastDogs.map((a, k) => {
                                    if (key === k) {
                                        var n = Object.assign({}, a);
                                        n[field] = value;
                                        return n;
                                    }
                                    return a;
                                })
                                setPastDogs(data);
                            }}
                            onAdd={() => {
                                setPastDogs((state) => ([...state, defaultPastDogs]));
                            }}
                            onRemove={(index) => {
                                var data = pastDogs.filter((a, i) => {
                                    return i !== index;
                                })
                                setPastDogs(data);
                            }}
                        />}

                        <div className="form-fields">
                            <h4>Other Pets</h4>
                        </div>
                        <div className="form-fields">
                            <div className="field-group col-span-2">
                                <label>Please list any other pets currently in residence</label>
                                <textarea resize="none" {...register("other_pets", { required: false })} />
                            </div>
                        </div>


                    </div>
                    <div title="Environment">
                        <h3>Environment</h3>
                        <div className="form-fields">
                            {environment_fields && environment_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    <label>{item.label} {item.required === true && <span className="req">*</span>}</label>
                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        <option value="">Select</option>
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s}>{s}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.type === 'radio' && <div className="flex flex-row">
                                        {item.data_source && item.data_source.map((f, key) => {
                                            return <label className="mr-3 force-font-normal" key={key}><input {...register(item.key, { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                        })}
                                    </div>}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}


                                </div>
                            })}
                        </div>
                    </div>

                    <div title="Reference">
                        <h3>Reference</h3>
                        <MultipleFields ref={referenceRef} title="Reference"
                            data={references} fields={reference_fields} onUpdateField={(key, field, value) => {
                                var data = references.map((a, k) => {
                                    if (key === k) {
                                        var n = Object.assign({}, a);
                                        n[field] = value;
                                        return n;
                                    }
                                    return a;
                                })
                                setReferences(data);
                            }}
                            onAdd={() => {
                                setReferences((state) => ([...state, defaultReference]));
                            }}
                            onRemove={(index) => {
                                var data = references.filter((a, i) => {
                                    return i !== index;
                                })
                                setReferences(data);
                            }}
                        />
                    </div>
                    <div title="Questions">
                        <h3>Questions</h3>
                        <div className="form-fields">
                            {questions_fields && questions_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    <label>{item.label} {item.required === true && <span className="req">*</span>}</label>

                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'textarea' && <textarea resize="none" {...register(item.key, { required: item.required })} />}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}

                                </div>
                            })}
                        </div>
                    </div>

                </RegisterTab>


            </form>
            <InlineLoader show={busy} />
        </div>
    </div>
}



const Sections = {
    Section1,
    Section2
}


export default Sections